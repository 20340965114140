<template>
  <Site id="app">
    <NavbarComponent message="IT Expert." :menus="menus" @open-modal-contact="openModalContact" />
    <Page>
      <router-view  @open-modal-contact="openModalContact"></router-view>
    </Page>
    <FooterComponent />
    <WhatsAppButton phoneNumber="+13146681412" company-name="Edwin Ariza" company-logo="https://edwinariza.dev/static/favicon.png" text="Hey Edwin, A need your help to develop ..." />
    <ModalContactComponent  :visible="isModalContact" :dataCOntact="dataContact" @close="closeModalContact"/>
  </Site>
</template>

<script>
import NavbarComponent from "../../edwinariza.ui/components/NavBar/NavBarComponent.vue";
import WhatsAppButton from "@/../../jumichica.vue.widget.whatsapp/src/components/Widget.vue";
import FooterComponent from "../../edwinariza.ui/components/Footer/FooterComponent.vue";
import ModalContactComponent from "../../edwinariza.ui/components/Modals/ModalContactComponent.vue";
export default {
  name: 'App',
  data(){
    return {
      isModalContact:false,
      loading:true,
      menus: [{
        id: 1,
        name: 'Programming Languages',
        url: '#programming_languages',
        submenus: [
          {
            id: 1,
            name: 'Python',
            url: '/python'
          },
          {
            id: 2,
            name: 'Vue',
            url: '/vue'
          },
          {
            id: 3,
            name: 'PHP',
            url: '/php'
          },
        ]
      },
      {
        id: 2,
        name: 'Blog',
        url: '/blog'
      }
      ],
      dataContact: {
          EN: {
            tag: 'Let me know your info.',
            title: 'Tell me your idea or plan.',
            content: "I'm ready to share opinions, ideas and code.",
            image: 'https://edwinariza.com/static/headers/contact/contact-3.svg'
          },
          ES: {
            tag: 'Dime como contactarte.',
            title: '¿Cuál es tu idea o plan?',
            content: 'Estoy listo para compartir opiniones, ideas y código.',
            image: 'https://edwinariza.com/static/headers/contact/contact-3.svg'
          }
        }
    }
  },
  components: {
    ModalContactComponent,
    FooterComponent,
    WhatsAppButton,
    NavbarComponent
  },
  methods:{
    openModalContact(){
      this.isModalContact=true;
    },
    closeModalContact(){
      this.isModalContact=false;
    }
  }
}
</script>
<style>
  html{
    font-size: 16px;
    font-family: "Quicksand", sans-serif;
  }
  #app {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background:#fefefe;
  }
  *{
    font-family:"Quicksand", sans-serif;
  }
</style>
