import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import { library } from '@fortawesome/fontawesome-svg-core';
import router from '@/router'
import './registerServiceWorker'
import VueMeta from 'vue-meta'

// INTERNAL ICONS
import { faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
  faArrowUp, faAngleRight, faAngleLeft, faAngleDown,
  faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faArrowDown, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
// ADD ICONS
library.add(faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
    faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faEye, faEyeSlash, faCaretDown,
    faCaretUp, faUpload, faLinkedin, faTwitter, faEyeSlash, faArrowDown, faArrowLeft, faArrowRight, faArrowUp);
Vue.component('vue-fontawesome', FontAwesomeIcon);
Vue.config.productionTip = false

// DETECT THE LANG
Vue.prototype.$lang = "en";
// CALL WITH
// this.$lang

if (process.env.NODE_ENV === 'production') {
  // PRODUCTION
  Vue.prototype.$url = "/";
  axios.defaults.baseURL = '/api';
}else{
  // DEVELOPER
  Vue.prototype.$url = "http://localhost:5000/api/";
  axios.defaults.baseURL = 'http://localhost:5000/api/';
}

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})
Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
