<template>
  <div class="home_view">
    <BannerComponent title="Senior Software Developer"
                     subtitle="for extensible software, libraries, bots, frameworks and components with Python, VUE and PHP - @eadev"
                     :image="require('@/assets/developer.svg')"
                     background="#0d0cb5"
                     color="#fefefe"
                     buttonName="Contract me"
                     buttonType="warning"
                     style="z-index:200;"
                     @open-modal-contact="$emit('open-modal-contact')"
    />
    <!--<NavLanding />-->
    <!--<IamComponent />-->
    <DeveloperBriefComponent />
    <!--<StepsComponent />-->
    <!--<BriefComponent />-->
    <ProjectsComponent title="Projects and Challenges" :projects="projects" />
    <!-- STARTUPS -->
    <!--<SystemicoBannerComponent />-->
    <!--<WydeaLabBannerComponent />-->
    <!--<PostsScrapidtComponent />-->
    <!--<JumichicaBannerComponent />-->
    <!--<ExperienceComponent />-->
    <PostsGithubComponent />
    <!--<div class="columns m-0 p-0">
      <div class="column">
        <SkillsComponent />
      </div>
      <div class="column">
        <TipComponent />
      </div>
    </div>-->
    <!-- SERVICES-->
    <!--<ProductsOptionsComponent class="has-background-grey-lighter p-3" id="pricing" />-->
    <!--<CertificationsComponent />-->
    <PostsComponent />
    <!--<FaqComponent />-->
  </div>
</template>

<script>
import BannerComponent from "../../../edwinariza.ui/components/Banner/BannerComponent.vue";
//import StepsComponent from "@/components/Steps/StepsComponent.vue";
import PostsComponent from "../../../edwinariza.ui/components/Posts/PostsComponent.vue";
//import FaqComponent from "@/components/Faq/FaqComponent.vue";
//import SkillsComponent from "@/components/Skills/SkillsComponent.vue";
//import TipComponent from "@/components/Tips/TipComponent.vue";
//import SystemicoBannerComponent from "@/components/Services/SystemicoBannerComponent.vue";
//import WydeaLabBannerComponent from "@/components/Services/WydeaBannerComponent.vue";
//import JumichicaBannerComponent from "@/components/Services/JumichicaBannerComponent.vue";
//import ProductsOptionsComponent from "@/components/Paypal/ProductsOptionsComponent.vue";
import PostsGithubComponent from "../../../edwinariza.ui/components/Posts/PostsGithubComponent.vue";
//import BriefComponent from "@/components/Intro/BriefComponent.vue";
//import PostsScrapidtComponent from "@/components/Posts/PostsScrapidtComponent.vue";
//import CertificationsComponent from "@/components/Content/CertificationsComponent.vue";
//import IamComponent from '@/components/Intro/IamComponent.vue';
//import NavLanding from "@/components/NavBar/NavLanding.vue";
//import ExperienceComponent from "@/components/Intro/ExperienceComponent.vue";
import DeveloperBriefComponent from '../../../edwinariza.ui/components/Intro/DeveloperBriefComponent.vue';
import ProjectsComponent from "../../../edwinariza.ui/components/Content/ProjectsComponent.vue";
import axios from "axios";

export default {
  name: 'HomeView',
  metaInfo: {
    title: 'Edwin Ariza: Senior Software Developer.',
    // override the parent template and just use the above title only
    titleTemplate: null
  },
  data(){
    return {
      projects: []
    }
  },
  components:{
    ProjectsComponent,
    //ExperienceComponent,
    //NavLanding,
    //CertificationsComponent,
    //BriefComponent,
    PostsGithubComponent,
    //ProductsOptionsComponent,
    //JumichicaBannerComponent,
    //WydeaLabBannerComponent,
    //SystemicoBannerComponent,
    //SkillsComponent,
    //FaqComponent,
    PostsComponent,
    //StepsComponent,
    BannerComponent,
    //TipComponent,
    DeveloperBriefComponent
    //PostsScrapidtComponent,
    //IamComponent
  },

  methods: {
    getProjects: function(){
      axios.get('https://edwinariza.com/api/projects/get_projects').then(response => {
        this.projects = response.data;
        this.isLoading = false;
      });
    },
  },
  beforeMount() {
    // CARGAMOS LOS ARTICULOS
    this.getProjects()
  },
}
</script>
<style lang="scss">
*{
  font-family:"Quicksand", sans-serif;
}
.home_view{
  background: #fefefe;
}
</style>